const greenLog = "color: green; font-weight: bold;";

/** log only if dev */
export function _devLog(...args: Parameters<typeof console.log>): void {
  if (
    typeof window !== "undefined" &&
    window.location.hostname === "localhost"
  ) {
    // eslint-disable-next-line no-console
    console.trace(`%c[devLog]`, greenLog, ...args);
  }
}
