import { CenteredPageBody } from "./CenteredPageBody";
import { CenteredPageBodyWide } from "./CenteredPageBodyWide";
import { FloatingPosition } from "./FloatingPosition";
import { SiteHeaderSection } from "./SiteHeaderSection";

export const layouts = {
  SiteHeaderSection,
  CenteredPageBody,
  CenteredPageBodyWide,
  FloatingPosition,
};
