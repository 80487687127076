/* eslint-disable @typescript-eslint/prefer-enum-initializers */

/**
 * 要素のID
 * アプリケーション側のロジックでもテスト用でも使いたい（data-testidよりこっちを使う）
 *
 * しいて言えば、このenumをロードできない環境では使えないので、そういう状況があればこういう自動生成でないIDを別途管理して使う
 * でもこのenumをロードできない環境でこのenumに依存したコードを書く事自体が変なはずなのでやっぱりこれでOKな気がする
 */
export enum DataId {
  ChordProgressionSearchBar_SearchBar,
  DegreeProgressionInput_SearchBar,
  SearchTypeSelector_Content,
  SearchConditionInput_Container,
  SearchConditionDetailInput_MultiSelectContent,
}

//TODO: これ、関数化したいときに使えないかな…？あとそもそもIDでなくdata-idを選んだ理由は…？
