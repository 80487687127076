import {
  ChordType,
  Extension,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { ChordSelectorProps } from ".";
import { sortArrayByGivenOrder } from "~/libs/array/sortArrayByGivenOrder";
import { ALTERNATIVE_CHORD_EXPRESSIONS } from "~/libs/chordProgressionUtil/ALTERNATIVE_CHORD_EXPRESSIONS";
import type { ToneType } from "~/libs/chordProgressionUtil/ToneType";
import {
  TONES_IN_NASHVILLE,
  TONES_IN_NUMBER_DEGREE,
} from "~/libs/chordProgressionUtil/tones";
import { exhaustiveGuard } from "~/libs/exhaustiveGuard";

export type ChordSelectorStateProps = {
  selectedRootNote: ChordSelectorProps["selectedRootNote"];
  selectedChordType: ChordSelectorProps["selectedChordType"];
  selectedExpansions: ChordSelectorProps["selectedExpansions"];
  selectedSlashCode: ChordSelectorProps["selectedSlashCode"];
  selectedAlternativeChordExpression: ChordSelectorProps["selectedAlternativeChordExpression"];
};

function convertChordTypeToString(chordType: ChordType): string {
  if (chordType === ChordType.Major) {
    return "";
  }

  return chordType;
}

export function convertChordSelectorStateToString({
  state,
  inputtingPlaceholder,
}: {
  state: ChordSelectorStateProps;
  inputtingPlaceholder: string;
}): string {
  // 空なら、プレースホルダーを返す
  if (
    !state.selectedAlternativeChordExpression &&
    !state.selectedRootNote &&
    !state.selectedChordType &&
    state.selectedExpansions.length === 0 &&
    !state.selectedSlashCode
  ) {
    return inputtingPlaceholder;
  }

  // 代替コード表記がある場合はそれを返す
  if (state.selectedAlternativeChordExpression) {
    return ALTERNATIVE_CHORD_EXPRESSIONS[
      state.selectedAlternativeChordExpression
    ].value;
  }

  const rootStr = state.selectedRootNote ?? "";

  const chordTypeStr = state.selectedChordType
    ? convertChordTypeToString(state.selectedChordType)
    : "";

  const expansionsStr =
    state.selectedExpansions.length > 0
      ? `(${sortArrayByGivenOrder(
          state.selectedExpansions,
          Object.values(Extension),
        ).join(",")})`
      : "";

  const slashCodeStr = state.selectedSlashCode
    ? `/${state.selectedSlashCode}`
    : "";

  return `${rootStr}${chordTypeStr}${expansionsStr}${slashCodeStr}`;
}

export function getTones(toneType: ToneType): readonly string[] {
  switch (toneType) {
    case "nashville": {
      return TONES_IN_NASHVILLE;
    }
    case "number_degree": {
      return TONES_IN_NUMBER_DEGREE;
    }
    default: {
      exhaustiveGuard(toneType);
      return [];
    }
  }
}
