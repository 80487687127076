export function sortArrayByGivenOrder(
  array: string[],
  order: string[],
): string[] {
  const orderMap = new Map(order.map((value, index) => [value, index]));

  return array.sort((a, b) => {
    const indexA = orderMap.get(a);
    const indexB = orderMap.get(b);

    // 順序が指定されていない場合は0（ソートしない）
    if (indexA === undefined || indexB === undefined) {
      return 0;
    }

    return indexA - indexB;
  });
}
