import { clsx } from "clsx";

type FloatingProps = {
  readonly position: "right-bottom" | "left-bottom";
  readonly className?: string;
  readonly children: React.ReactNode;
};

export function FloatingPosition({
  position = "right-bottom",
  className,
  children,
}: FloatingProps): JSX.Element {
  return (
    <div
      className={clsx(
        "fixed z-50 size-fit",
        {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          "bottom-8 right-4": position === "right-bottom",
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          "bottom-8 left-4": position === "left-bottom",
        },
        className,
      )}
    >
      {children}
    </div>
  );
}
