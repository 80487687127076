// eslint-disable-next-line max-statements
export function createQueryParamsString<
  T extends Record<PropertyKey, unknown> = Record<PropertyKey, unknown>,
>(params: T): string {
  if (Object.keys(params).length === 0) {
    return "";
  }

  const query = new URLSearchParams();

  // eslint-disable-next-line unicorn/no-array-for-each
  Object.entries(params).forEach(([key, value]) => {
    if (value === undefined) {
      return;
    }

    if (typeof value === "object") {
      query.set(key, JSON.stringify(value));
      return;
    }

    query.set(key, String(value));
  });

  return `?${query.toString()}`;
}
