export const TONES_IN_NASHVILLE = [
  "C",
  "C#",
  "Cb",
  "D",
  "D#",
  "Db",
  "E",
  "E#",
  "Eb",
  "F",
  "F#",
  "Fb",
  "G",
  "G#",
  "Gb",
  "A",
  "A#",
  "Ab",
  "B",
  "B#",
  "Bb",
] as const;
export type NashvilleTone = (typeof TONES_IN_NASHVILLE)[number];

export const TONES_IN_NUMBER_DEGREE = [
  "1",
  "1#",
  "1b",
  "2",
  "2#",
  "2b",
  "3",
  "3#",
  "3b",
  "4",
  "4#",
  "4b",
  "5",
  "5#",
  "5b",
  "6",
  "6#",
  "6b",
  "7",
  "7#",
  "7b",
] as const;
export type NumberDegreeTone = (typeof TONES_IN_NUMBER_DEGREE)[number];
