import type { ChordExpression } from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import type { AppTranslationDictionary } from "../i18n/localeTypes";

type ChordExpressionType = ChordExpression["type"];

export type AlternativeChordExpressionTypeExclude = Exclude<
  ChordExpressionType,
  "chord"
>;

type ALTERNATIVE_CHORD_EXPRESSIONS_TYPE = {
  [key in AlternativeChordExpressionTypeExclude]: {
    readonly value: string;
    readonly descriptionTranslationKey: keyof AppTranslationDictionary;
  };
};

export const ALTERNATIVE_CHORD_EXPRESSIONS = {
  noChord: {
    value: "_",
    descriptionTranslationKey: "when_chord_is_unknown",
  },
  same: {
    value: "%",
    descriptionTranslationKey: "when_the_same_as_the_previous_code",
  },
  unIdentified: {
    value: "?",
    descriptionTranslationKey: "when_there_is_no_chord",
  },
} as const satisfies ALTERNATIVE_CHORD_EXPRESSIONS_TYPE;
