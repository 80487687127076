/* eslint-disable react/jsx-handler-names */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-max-depth */
import { useId } from "react";
import { getFormProps, useInputControl, type useForm } from "@conform-to/react";
import { RadioContainer } from "../../RadioContainer";
import { MultiSelect } from "../../_form/MultiSelect";
import { SearchConditionPartLayout } from "./SearchConditionPartLayout";
import { Checkbox } from "~/components/shadcnui/ui/checkbox";
import { DataId } from "~/libs/DataId";
import { Input } from "~/components/shadcnui/ui/input";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

// TODO: 参考: https://tech.dely.jp/entry/2024/03/19/164444

// TODO: 以下utilとかにずらしたいかも
export type SearchTargetSectionSpecifiedState = "all" | "specified";
export type SearchTargetSectionOverlapIncludedState =
  | "not_overlapped"
  | "overlapped";
export type SearchTargetPosition = "all" | "start" | "end";
export type MatchingTolerance = "fuzzy_match" | "exact_match";
export type TrackAttributeSpecifiedState = "all" | "specified";

export type SearchConditionDetailInputFormSchema = {
  //
  searchTargetSectionSpecifiedState: SearchTargetSectionSpecifiedState;
  searchTargetSections: string[];
  searchTargetSectionOverlapIncludedState: SearchTargetSectionOverlapIncludedState;
  //
  searchTargetPosition: SearchTargetPosition;
  //
  matchingTolerance: MatchingTolerance;
  //
  trackAttributeSpecifiedState: TrackAttributeSpecifiedState;
  trackAttributeCountries: string[];
  trackAttributeArtists: string;
  trackAttributeAlbums: string;
  trackAttributeTags: string[];
};

// TODO: こういうほぼ初期値なやつ、どっかにまとめておきたい
export const initialSearchConditionDetailInputFormValue: SearchConditionDetailInputFormSchema =
  {
    searchTargetSectionSpecifiedState: "all",
    searchTargetSections: [],
    searchTargetSectionOverlapIncludedState: "not_overlapped",
    searchTargetPosition: "all",
    matchingTolerance: "fuzzy_match",
    trackAttributeSpecifiedState: "all",
    trackAttributeCountries: [],
    trackAttributeTags: [],
    trackAttributeArtists: "",
    trackAttributeAlbums: "",
  };

export type SearchConditionDetailInputProps = {
  readonly formState: ReturnType<
    typeof useForm<SearchConditionDetailInputFormSchema>
  >;
  readonly searchTargetSection: {
    options: {
      sections: string[];
    };
    onOpen: {
      sectionsMultiSelect: () => void;
    };
  };
  readonly trackAttribute: {
    options: {
      countries: string[];
      tags: string[];
    };
    onOpen: {
      countriesMultiSelect: () => void;
      tagsMultiSelect: () => void;
    };
  };
};

export function SearchConditionDetailInput({
  formState: [form, formFields],
  searchTargetSection,
  trackAttribute,
}: SearchConditionDetailInputProps): JSX.Element {
  const dict = useDictionary();
  const allowCrossingNextSectionCheckboxId = useId();

  const formControls = {
    searchTargetSectionSpecifiedState: useInputControl(
      formFields.searchTargetSectionSpecifiedState,
    ),
    searchTargetSections: useInputControl(formFields.searchTargetSections),
    searchTargetSectionOverlapIncludedState: useInputControl(
      formFields.searchTargetSectionOverlapIncludedState,
    ),
    searchTargetPositionControl: useInputControl(
      formFields.searchTargetPosition,
    ),
    matchingTolerance: useInputControl(formFields.matchingTolerance),
    trackAttributeSpecifiedState: useInputControl(
      formFields.trackAttributeSpecifiedState,
    ),
    trackAttributeCountries: useInputControl(
      formFields.trackAttributeCountries,
    ),
    trackAttributeArtists: useInputControl(formFields.trackAttributeArtists),
    trackAttributeAlbums: useInputControl(formFields.trackAttributeAlbums),
    trackAttributeTags: useInputControl(formFields.trackAttributeTags),
  };

  return (
    <form className="flex flex-col gap-8" {...getFormProps(form)}>
      <SearchConditionPartLayout
        title={dict.search_target_section}
        condition={
          <div className="flex w-full flex-col gap-1">
            <RadioContainer
              onBlur={formControls.searchTargetSectionSpecifiedState.blur}
              isSelected={
                formControls.searchTargetSectionSpecifiedState.value === "all"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.searchTargetSectionSpecifiedState.change("all");
              }}
              onFocus={formControls.searchTargetSectionSpecifiedState.focus}
            >
              <div>{dict.all}</div>
            </RadioContainer>

            <RadioContainer
              onBlur={formControls.searchTargetSectionSpecifiedState.blur}
              isSelected={
                formControls.searchTargetSectionSpecifiedState.value ===
                "specified"
              }
              isChildrenSelectable={false}
              onSelected={() => {
                formControls.searchTargetSectionSpecifiedState.change(
                  "specified",
                );
              }}
              onFocus={formControls.searchTargetSectionSpecifiedState.focus}
            >
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-2">
                  <div>{dict.specify}:</div>
                  <div className="min-w-40">
                    <MultiSelect
                      dataContentId={String(
                        DataId.SearchConditionDetailInput_MultiSelectContent,
                      )}
                      options={searchTargetSection.options.sections}
                      values={
                        typeof formControls.searchTargetSections.value ===
                        "string"
                          ? [formControls.searchTargetSections.value]
                          : formControls.searchTargetSections.value ?? []
                      }
                      onBlur={formControls.searchTargetSections.blur}
                      onOpen={searchTargetSection.onOpen.sectionsMultiSelect}
                      onChange={(sections) => {
                        formControls.searchTargetSections.change(sections);
                      }}
                      onFocus={formControls.searchTargetSections.focus}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center gap-2">
                  <Checkbox
                    id={allowCrossingNextSectionCheckboxId}
                    defaultChecked={
                      formControls.searchTargetSectionOverlapIncludedState
                        .value === "overlapped"
                    }
                    onCheckedChange={(value) => {
                      formControls.searchTargetSectionOverlapIncludedState.change(
                        value ? "overlapped" : "not_overlapped",
                      );
                    }}
                    onBlur={
                      formControls.searchTargetSectionOverlapIncludedState.blur
                    }
                    onFocus={
                      formControls.searchTargetSectionOverlapIncludedState.focus
                    }
                  />
                  <label
                    htmlFor={allowCrossingNextSectionCheckboxId}
                    className="py-1 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    {dict.allow_crossing_next_section}
                  </label>
                </div>
              </div>
            </RadioContainer>
          </div>
        }
      />

      <SearchConditionPartLayout
        title={dict.search_target_position}
        condition={
          <div className="flex w-full flex-col gap-1">
            <RadioContainer
              onBlur={formControls.searchTargetPositionControl.blur}
              isSelected={
                formControls.searchTargetPositionControl.value === "all"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.searchTargetPositionControl.change("all");
              }}
              onFocus={formControls.searchTargetPositionControl.focus}
            >
              <div>{dict.all}</div>
            </RadioContainer>
            <RadioContainer
              onBlur={formControls.searchTargetPositionControl.blur}
              isSelected={
                formControls.searchTargetPositionControl.value === "start"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.searchTargetPositionControl.change("start");
              }}
              onFocus={formControls.searchTargetPositionControl.focus}
            >
              <div>{dict.beginning_with}</div>
            </RadioContainer>
            <RadioContainer
              onBlur={formControls.searchTargetPositionControl.blur}
              isSelected={
                formControls.searchTargetPositionControl.value === "end"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.searchTargetPositionControl.change("end");
              }}
              onFocus={formControls.searchTargetPositionControl.focus}
            >
              <div>{dict.end_with}</div>
            </RadioContainer>
          </div>
        }
      />

      <SearchConditionPartLayout
        title={dict.matching_tolerance}
        condition={
          <div className="flex w-full flex-col gap-1">
            <RadioContainer
              isSelected={
                formControls.matchingTolerance.value === "fuzzy_match"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.matchingTolerance.change("fuzzy_match");
              }}
              onBlur={formControls.matchingTolerance.blur}
              onFocus={formControls.matchingTolerance.focus}
            >
              <div>{dict.fuzzy_match}</div>
            </RadioContainer>
            <RadioContainer
              isSelected={
                formControls.matchingTolerance.value === "exact_match"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.matchingTolerance.change("exact_match");
              }}
              onBlur={formControls.matchingTolerance.blur}
              onFocus={formControls.matchingTolerance.focus}
            >
              <div>{dict.exact_match}</div>
            </RadioContainer>
          </div>
        }
      />

      <SearchConditionPartLayout
        title={dict.track_attributes}
        condition={
          <div className="flex w-full flex-col gap-1">
            <RadioContainer
              isSelected={
                formControls.trackAttributeSpecifiedState.value === "all"
              }
              isChildrenSelectable
              onSelected={() => {
                formControls.trackAttributeSpecifiedState.change("all");
              }}
              onBlur={formControls.trackAttributeSpecifiedState.blur}
              onFocus={formControls.trackAttributeSpecifiedState.focus}
            >
              <div>{dict.all}</div>
            </RadioContainer>

            <RadioContainer
              isSelected={
                formControls.trackAttributeSpecifiedState.value === "specified"
              }
              isChildrenSelectable={false}
              onSelected={() => {
                formControls.trackAttributeSpecifiedState.change("specified");
              }}
              onBlur={formControls.trackAttributeSpecifiedState.blur}
              onFocus={formControls.trackAttributeSpecifiedState.focus}
            >
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <div>{dict.country}:</div>
                  <div className="min-w-40">
                    <MultiSelect
                      dataContentId={String(
                        DataId.SearchConditionDetailInput_MultiSelectContent,
                      )}
                      options={trackAttribute.options.countries}
                      values={
                        typeof formControls.trackAttributeCountries.value ===
                        "string"
                          ? [formControls.trackAttributeCountries.value]
                          : formControls.trackAttributeCountries.value ?? []
                      }
                      onOpen={trackAttribute.onOpen.countriesMultiSelect}
                      onChange={(values) => {
                        formControls.trackAttributeCountries.change(values);
                      }}
                      onBlur={formControls.trackAttributeCountries.blur}
                      onFocus={formControls.trackAttributeCountries.focus}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div>{dict.artist}:</div>
                  <div className="min-w-40">
                    <Input
                      name="trackAttributeArtists"
                      defaultValue={formControls.trackAttributeArtists.value}
                      onFocus={formControls.trackAttributeArtists.focus}
                      onChange={(e) => {
                        formControls.trackAttributeArtists.change(
                          e.target.value,
                        );
                      }}
                      onBlur={formControls.trackAttributeArtists.blur}
                    />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-2">
                    <div>{dict.album}:</div>
                    <div className="min-w-40">
                      <Input
                        defaultValue={formControls.trackAttributeArtists.value}
                        onChange={(e) => {
                          formControls.trackAttributeAlbums.change(
                            e.target.value,
                          );
                        }}
                        onBlur={formControls.trackAttributeAlbums.blur}
                        onFocus={formControls.trackAttributeAlbums.focus}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div>{dict.tag}:</div>
                  <div className="min-w-40">
                    <MultiSelect
                      dataContentId={String(
                        DataId.SearchConditionDetailInput_MultiSelectContent,
                      )}
                      options={trackAttribute.options.tags}
                      values={
                        typeof formControls.trackAttributeTags.value ===
                        "string"
                          ? [formControls.trackAttributeTags.value]
                          : formControls.trackAttributeTags.value ?? []
                      }
                      onOpen={trackAttribute.onOpen.tagsMultiSelect}
                      onChange={(values) => {
                        formControls.trackAttributeTags.change(values);
                      }}
                      onBlur={formControls.trackAttributeTags.blur}
                      onFocus={formControls.trackAttributeTags.focus}
                    />
                  </div>
                </div>
              </div>
            </RadioContainer>
          </div>
        }
      />
    </form>
  );
}
