import { useNavigate } from "@remix-run/react";
import type { DropDownContentItem } from "../DropDownContent";
import { Icons } from "../_icons";
import { getAddChordProgressionPagePath } from "~/routes/tracks.new/path";
import { _devLog } from "~/libs/log/_devLog";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import { getNewNamingPagePath } from "~/routes/namings.new/path";
import { getNewListPagePath } from "~/routes/lists.new/path";

export function useAddPagesContentItems(): DropDownContentItem[] {
  const dict = useDictionary();
  const navigate = useNavigate();
  return [
    {
      type: "clickableLabel",
      icon: <Icons.Domain.ChordProgression size={14} />,
      label: dict.chord_progressions,
      onClick: () => {
        _devLog("onClick add chord_progressions link");
        navigate(getAddChordProgressionPagePath());
      },
    },
    {
      type: "clickableLabel",
      icon: <Icons.Domain.Naming size={14} />,
      label: dict.naming,
      onClick: () => {
        _devLog("onClick add naming link");
        navigate(getNewNamingPagePath());
      },
    },
    {
      type: "clickableLabel",
      icon: <Icons.Domain.List size={14} />,
      label: dict.list,
      onClick: () => {
        _devLog("onClick add list link");
        navigate(getNewListPagePath());
      },
    },
  ];
}
