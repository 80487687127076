import {
  SelectorButton,
  type SelectorButtonProps,
} from "~/components/common/SelectorButton";

export type ChordSelectorButtonsProps = {
  /** n個のコード進行が入るn個の小節、ということで2次元配列になっている */
  readonly selectorButtonPropsLists: SelectorButtonProps[][];
  readonly noHighlight?: boolean;
};

// TODO: Mだけ選ぶと空白になって、その時に何かわからなくなるから、エラー系のは色をつけるとか…
export function ChordSelectorButtons({
  selectorButtonPropsLists,
  noHighlight,
}: ChordSelectorButtonsProps): JSX.Element {
  return (
    <div className="flex flex-wrap">
      {selectorButtonPropsLists.map((propsList, propsListIndex) => (
        <div
          key={propsListIndex}
          className="flex items-center whitespace-nowrap"
        >
          {propsListIndex > 0 && <span className="mx-1">−</span>}

          {propsList.map((props, propsIndex) => {
            return (
              <div key={propsIndex} className="flex items-center">
                <SelectorButton
                  {...props}
                  {...(noHighlight ? { isSelected: false } : {})}
                />
                {propsIndex < propsList.length - 1 && (
                  <span className="">,</span>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}
