import { clsx } from "clsx";

export type DividerProps = {
  readonly text?: string;
  readonly className?: string;
  readonly isLight?: boolean;
};

export function Divider({
  text,
  className,
  isLight,
}: DividerProps): JSX.Element {
  return (
    <div className={clsx("flex w-full items-center", className)}>
      <div
        className={clsx(
          "h-px w-full ",
          isLight ? "bg-primary-300" : "bg-primary-500",
        )}
      >
        {" "}
      </div>
      {text ? (
        <span className={clsx("whitespace-nowrap px-4")}>{text}</span>
      ) : undefined}
      <div
        className={clsx(
          "h-px w-full ",
          isLight ? "bg-primary-300" : "bg-primary-500",
        )}
      />
    </div>
  );
}
