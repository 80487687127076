import {
  Trash,
  HomeIcon,
  ListMusicIcon,
  TagIcon,
  ListFilterIcon,
  Loader2Icon,
  PlusIcon,
  ArrowLeft,
  FlagIcon,
  Pencil,
  PencilIcon,
  EllipsisIcon,
  SearchIcon,
  ArrowRight,
  UserIcon,
  ChevronsRightIcon,
  ChevronsLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  SaveIcon,
  MenuIcon,
  Palette,
  ChevronDown,
  FileMusic,
  CheckIcon,
  CopyPlusIcon,
  MoveUp,
  MoveDown,
  BellRingIcon,
  StarIcon,
  CircleAlertIcon,
  KeyRoundIcon,
  MailIcon,
  InfoIcon,
  LogOutIcon,
  TriangleAlertIcon,
  CircleIcon,
  CircleCheckIcon,
  LogInIcon,
  EyeIcon,
  EyeOffIcon,
  MicroscopeIcon,
  SendIcon,
} from "lucide-react";

export const Icons = {
  Domain: {
    Home: HomeIcon,
    ChordProgression: FileMusic,
    List: ListMusicIcon,
    Naming: TagIcon,
    Favorite: StarIcon,
    Private: KeyRoundIcon,
  },
  Crud: {
    // Create
    Plus: PlusIcon,
    // Read
    Search: SearchIcon,
    Microscope: MicroscopeIcon,
    // Update
    Pencil,
    EditedPencil: PencilIcon,
    Save: SaveIcon,
    // Delete
    Trash,
  },
  Util: {
    LogIn: LogInIcon,
    LogOut: LogOutIcon,
    Info: InfoIcon,
    Mail: MailIcon,
    Circle: CircleIcon,
    CircleCheck: CircleCheckIcon,
    Alert: CircleAlertIcon,
    AlertTriangle: TriangleAlertIcon,
    BellRing: BellRingIcon,
    Theme: Palette,
    ThreeDots: EllipsisIcon,
    Report: FlagIcon,
    ListFilter: ListFilterIcon,
    Loading: Loader2Icon,
    Send: SendIcon,
    Arrows: {
      Left: ArrowLeft,
      LeftChevron: ChevronLeftIcon,
      LeftChevrons: ChevronsLeftIcon,
      Right: ArrowRight,
      RightChevron: ChevronRightIcon,
      RightChevrons: ChevronsRightIcon,
      Up: MoveUp,
      Down: MoveDown,
      DownChevron: ChevronDown,
    },
    Check: CheckIcon,
    Menu: MenuIcon,
    User: UserIcon,
    ExternalLink: ExternalLinkIcon,
    Duplicate: CopyPlusIcon,
    Eye: EyeIcon,
    EyeOff: EyeOffIcon,
  },
};
