"use client";
import { clsx } from "clsx";
import { Button } from "~/components/shadcnui/ui/button";

export type SelectorButtonProps = {
  readonly placeholder: React.ReactNode;
  readonly isSelected: boolean;
  readonly className?: string;
  readonly buttonRef?: React.LegacyRef<HTMLButtonElement>;
  readonly onClick: () => void;
};

export function SelectorButton({
  placeholder,
  isSelected,
  className,
  buttonRef,
  onClick,
}: SelectorButtonProps): JSX.Element {
  return (
    <Button
      ref={buttonRef}
      type="button"
      variant="outline"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={clsx(
        "rounded-sm px-2",
        isSelected
          ? "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground"
          : "border-none",
        className,
      )}
    >
      {placeholder}
    </Button>
  );
}
