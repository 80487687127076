import { clsx } from "clsx";

export type CenteredPageBodyProps = {
  readonly children: React.ReactNode;
  readonly className?: string;
};

export function CenteredPageBody({
  children,
  className,
}: CenteredPageBodyProps): JSX.Element {
  return (
    <div className={clsx("mx-auto mt-8 max-w-5xl px-4", className)}>
      {children}
    </div>
  );
}
