import { useRef } from "react";
import { useClickAway } from "react-use";
import {
  ChordSelector,
  type ChordSelectorProps,
} from "../_ChordSelectors/ChordSelector";
import { Divider } from "../Divider";
import {
  ChordFunctionSelector,
  type ChordFunctionSelectorProps,
} from "../_ChordSelectors/ChordFunctionSelector";
import type { ChordSearchType } from "../SiteHeader/ChordProgressionSearchBar/SearchTypeSelector/util";
import {
  SearchConditionDetailInput,
  type SearchConditionDetailInputProps,
} from "./SearchConditionDetailInput";
import { DataId } from "~/libs/DataId";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type SearchConditionInputProps = {
  readonly chordSearchType: ChordSearchType;
  readonly chordSelectorProps: ChordSelectorProps;
  readonly chordFunctionSelectorProps: ChordFunctionSelectorProps;
  readonly searchConditionDetailInputProps: SearchConditionDetailInputProps;
  readonly onClickOutside: (e: Event) => void;
};

export function SearchConditionInput({
  chordSearchType,
  chordSelectorProps,
  chordFunctionSelectorProps,
  searchConditionDetailInputProps,
  onClickOutside,
}: SearchConditionInputProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const dict = useDictionary();

  useClickAway(ref, (e) => {
    onClickOutside(e);
  });

  return (
    <div
      className="p-2"
      ref={ref}
      data-id={DataId.SearchConditionInput_Container}
    >
      <div className="rounded-md border p-1 shadow-xl">
        {chordSearchType === "chord" && (
          <ChordSelector {...chordSelectorProps} />
        )}
        {chordSearchType === "degree" && (
          <ChordSelector {...chordSelectorProps} />
        )}
        {chordSearchType === "function" && (
          <ChordFunctionSelector {...chordFunctionSelectorProps} />
        )}
      </div>

      <div className="mt-12 px-8">
        <h3>
          <Divider text={dict.advanced_search_criteria} />
        </h3>
        <div className="pb-8 pt-4">
          <SearchConditionDetailInput {...searchConditionDetailInputProps} />
        </div>
      </div>
    </div>
  );
}
