import {
  ChordType,
  Extension,
} from "@lainnao/chord-progression-parser-bundler/generatedTypes";
import { clsx } from "clsx";
import { SelectorButton } from "../../SelectorButton";
import { ChordElementLane } from "../_util/ChordElementLane";
import { OutlineTitle } from "../_util/OutlineTitle";
import { AlternativeChordExpressionsLane } from "../_util/AlternativeChordExpressionsLane";
import { getTones } from "./util";
import type { AlternativeChordExpressionTypeExclude } from "~/libs/chordProgressionUtil/ALTERNATIVE_CHORD_EXPRESSIONS";
import type { ToneType } from "~/libs/chordProgressionUtil/ToneType";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type ChordSelectorProps = {
  readonly className?: string;
  readonly toneType: ToneType;
  readonly selectedRootNote?: string;
  readonly selectedChordType?: ChordType;
  readonly selectedExpansions: string[];
  readonly selectedSlashCode?: string;
  readonly selectedAlternativeChordExpression?: AlternativeChordExpressionTypeExclude;
  readonly onClickRootNote: (rootNote: string) => void;
  readonly onClickChordType: (chordType: ChordType) => void;
  readonly onClickExpansion: (expansion: string) => void;
  readonly onClickSlashCode: (slashCode: string) => void;
  readonly onClickAlternativeChordExpression: (
    alternativeChordExpression: AlternativeChordExpressionTypeExclude,
  ) => void;
  readonly onClickClearInputButton: () => void;
  readonly onClickDeleteButton: () => void;
};

export function ChordSelector({
  className,
  toneType,
  selectedRootNote,
  selectedChordType,
  selectedExpansions,
  selectedSlashCode,
  selectedAlternativeChordExpression,
  onClickRootNote,
  onClickChordType,
  onClickExpansion,
  onClickSlashCode,
  onClickAlternativeChordExpression,
  onClickClearInputButton,
  onClickDeleteButton,
}: ChordSelectorProps): JSX.Element {
  const dict = useDictionary();

  const tones = getTones(toneType);

  return (
    <div className={clsx("flex", className)}>
      <div className="flex w-4/5 flex-col  gap-4">
        <OutlineTitle>
          <div className="flex flex-col items-center justify-center gap-1">
            <span>{dict.please_select_the_components_of_the_code}</span>
            <span className="text-center text-xs">
              {dict.please_select_the_components_of_the_code_description(
                toneType,
              )}
            </span>
          </div>
        </OutlineTitle>

        <div className="flex flex-1">
          <ChordElementLane
            className="flex-1"
            title={dict.root_note}
            body={tones.map((tone) => (
              <div key={tone} className="w-full px-1">
                <SelectorButton
                  isSelected={selectedRootNote === tone}
                  placeholder={tone}
                  className="w-full"
                  onClick={() => {
                    onClickRootNote(tone);
                  }}
                />
              </div>
            ))}
          />
          <ChordElementLane
            className="flex-1 border-l-2"
            title={dict.chord_type}
            body={Object.values(ChordType).map((chordType) => (
              <div key={chordType} className="w-full px-1">
                <SelectorButton
                  key={chordType}
                  isSelected={selectedChordType === chordType}
                  placeholder={chordType}
                  className="w-full"
                  onClick={() => {
                    onClickChordType(chordType);
                  }}
                />
              </div>
            ))}
          />
          <ChordElementLane
            className="flex-1 border-l-2"
            title={
              <div className="flex flex-col gap-1 text-center">
                <span className="text-[0.7rem]">
                  {dict.expansion_or_addition}
                </span>
                <span className="text-[0.6rem] leading-3">
                  ({dict.multi_select})
                </span>
              </div>
            }
            body={Object.values(Extension).map((expansion) => (
              <div key={expansion} className="w-full px-1">
                <SelectorButton
                  key={expansion}
                  isSelected={selectedExpansions.includes(expansion)}
                  placeholder={expansion}
                  className="w-full"
                  onClick={() => {
                    onClickExpansion(expansion);
                  }}
                />
              </div>
            ))}
          />
          <ChordElementLane
            className="flex-1 border-l-2"
            title={dict.slash_code}
            body={tones.map((tone) => (
              <div key={tone} className="w-full px-1">
                <SelectorButton
                  key={tone}
                  isSelected={selectedSlashCode === tone}
                  placeholder={tone}
                  className="w-full"
                  onClick={() => {
                    onClickSlashCode(tone);
                  }}
                />
              </div>
            ))}
          />
        </div>
      </div>

      <div className="w-1/5 border-l-2">
        <AlternativeChordExpressionsLane
          selectedAlternativeChordExpression={
            selectedAlternativeChordExpression
          }
          onClickAlternativeChordExpression={onClickAlternativeChordExpression}
          onClickClearInputButton={onClickClearInputButton}
          onClickDeleteButton={onClickDeleteButton}
        />
      </div>
    </div>
  );
}
