/* eslint-disable react/jsx-max-depth */
import { clsx } from "clsx";
import { Icons } from "../_icons";
import {
  DropDownContents,
  type DropDownContentsProps,
} from "../DropDownContent";
import { SiteMenuLink, type SiteMenuLinkProps } from "./SiteMenuLink";
import { useAddPagesContentItems } from "./util";
import { Button } from "~/components/shadcnui/ui/button";
import { FloatingPosition } from "~/components/layout/FloatingPosition";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "~/components/shadcnui/ui/dropdown-menu";

type Theme = {
  id: string;
  label: string;
  isSelected: boolean;
};

export type SiteMenuLinksProps = {
  readonly links: SiteMenuLinkProps[];
  readonly themes: Theme[];
  readonly noFAB?: boolean;
  readonly isLogined: boolean;
  readonly onSelectTheme: (id: string) => void;
};

function getFilterableTheme({
  themes,
  messages,
  onSelect,
}: {
  themes: Theme[];
  messages: {
    filterPlaceHolder: string;
    emptyMessage: string;
  };
  onSelect: (id: string) => void;
}): DropDownContentsProps["contents"][number] {
  return {
    type: "filterableCommands",
    filterPlaceholder: messages.filterPlaceHolder,
    emptyMessage: messages.emptyMessage,
    commands: themes,
    onSelect,
  };
}

// TODO: スマホの場合、左下にフローティングアクションボタンみたいなの作って、そこからメニュー出せるようにするやつにする
// TODO: となると右下のフローティングアクションリンク、邪魔だな…かぶるし。どうする…？ Post Chord Progression、をメニューの右恥に入れるか？
// TODO: 選択済みのテーマはそれが分かるようにする
export function SiteMenuLinks({
  links,
  themes,
  noFAB,
  isLogined,
  onSelectTheme,
}: SiteMenuLinksProps): JSX.Element {
  const dict = useDictionary();

  const filterableThemesContents = getFilterableTheme({
    themes,
    onSelect: onSelectTheme,
    messages: {
      filterPlaceHolder: dict.search_themes,
      emptyMessage: dict.no_data,
    },
  });

  const addPagesContents = useAddPagesContentItems();

  /** スマホ時の左下のメニュー */
  const smartPhoneBottomMenu = (
    <FloatingPosition position="left-bottom">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="size-14 rounded-full bg-secondary shadow-xl"
            variant="outline"
          >
            <Icons.Util.Menu width={24} height={24} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="ml-4 border-2 border-primary shadow-xl">
          <DropdownMenuLabel>{dict.site_menu}</DropdownMenuLabel>
          {links.map((link) => (
            <DropdownMenuItem key={link.href} className="w-full">
              <SiteMenuLink {...link} className="w-full text-lg" />
            </DropdownMenuItem>
          ))}
          <DropDownContents
            contents={[
              ...(isLogined
                ? ([
                    {
                      type: "sub",
                      label: dict.add_new.toUpperCase(),
                      icon: (
                        <Icons.Crud.Plus
                          size={14}
                          className="text-foreground/60"
                        />
                      ),
                      contents: addPagesContents,
                    },
                  ] as const)
                : []),
              {
                type: "sub",
                label: dict.theme.toUpperCase(),
                icon: (
                  <Icons.Util.Theme size={14} className="text-foreground/60" />
                ),
                contents: [filterableThemesContents],
              },
            ]}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </FloatingPosition>
  );

  /** スマホサイズより大きい時の上側のメニュー */
  const largerThanSmartPhoneMenu = (
    <>
      {links.map((link) => (
        <SiteMenuLink key={`${link.label}_${link.href}`} {...link} />
      ))}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button
            type="button"
            className="flex items-center gap-1 px-2 pb-1 text-xs font-semibold hover:text-foreground/80"
          >
            <Icons.Util.Theme size={14} className="text-foreground/60" />
            <span>{dict.theme.toUpperCase()}</span>
            <Icons.Util.Arrows.DownChevron size="14" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="ml-4 border-2 border-primary shadow-xl">
          <DropDownContents contents={[filterableThemesContents]} />
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );

  return (
    <>
      {/* ログイン画面の時とかFAB邪魔なので消したい時にnoFABをtrueにする */}
      {!noFAB && <nav className="sm:hidden">{smartPhoneBottomMenu}</nav>}

      <nav
        className={clsx(
          "hidden sm:flex",
          "w-full flex-wrap justify-start gap-2 pt-2 sm:gap-4",
        )}
      >
        {largerThanSmartPhoneMenu}
      </nav>
    </>
  );
}
