import * as React from "react";
import { Check, X, ChevronsUpDown } from "lucide-react";
import { clsx } from "clsx";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/shadcnui/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/shadcnui/ui/popover";
import { Badge } from "~/components/shadcnui/ui/badge";
import { Button } from "~/components/shadcnui/ui/button";

export type MultiSelectProps = {
  readonly className?: string;
  readonly dataContentId?: string;
  /** NOTE: 文字列がかぶらないようにすること */
  readonly options: string[];
  readonly values: string[];
  readonly isLoading?: boolean;
  readonly onChange: (values: string[]) => void;
  readonly onOpen?: () => void;
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "values" | "options" | "onChange"
>;

// TODO: 多言語化
export function MultiSelect({
  className,
  dataContentId,
  options,
  values,
  onChange,
  onOpen,
  ...props
}: MultiSelectProps): JSX.Element {
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (nextOpeningState: boolean) => {
    setOpen(nextOpeningState);
    if (nextOpeningState) {
      onOpen?.();
    }
  };

  const handleUnselect = (item: string) => {
    onChange(values.filter((selectedItem) => selectedItem !== item));
  };

  return (
    <Popover open={open} onOpenChange={handleOpenChange} {...props}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={clsx(
            `w-full justify-between hover:bg-inherit hover:ring-2`,
            values.length > 1 ? "h-full" : "h-10",
          )}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="flex flex-wrap justify-center gap-1 px-1">
            {values.map((item) => (
              <Badge
                variant="secondary"
                key={item}
                onClick={() => {
                  handleUnselect(item);
                }}
              >
                {item}
                {/* HACK: buttonだと <button> cannot appear as a descendant of <button> の警告が出るのでdivにする */}
                <div
                  role="button"
                  tabIndex={0}
                  className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleUnselect(item);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => {
                    handleUnselect(item);
                  }}
                >
                  <X className="size-3 text-muted-foreground hover:text-foreground" />
                </div>
              </Badge>
            ))}
          </div>
          <ChevronsUpDown className="size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-full p-0" data-id={dataContentId}>
        <Command className={className}>
          <CommandInput placeholder="Search ..." />
          {options.length > 0 ? (
            <CommandGroup className="max-h-64 overflow-auto">
              {options.map((option) => (
                <CommandItem
                  key={option}
                  onSelect={() => {
                    onChange(
                      values.includes(option)
                        ? values.filter((item) => item !== option)
                        : [...values, option],
                    );
                    setOpen(true);
                  }}
                >
                  <Check
                    className={clsx(
                      "mr-2 size-4",
                      values.includes(option) ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {option}
                </CommandItem>
              ))}
            </CommandGroup>
          ) : // eslint-disable-next-line unicorn/no-negated-condition
          !props.isLoading ? (
            <div className="p-3 text-center text-sm text-muted-foreground">
              No item found.
            </div>
          ) : undefined}
          {!!props.isLoading && (
            <div className="p-3 text-center text-sm text-muted-foreground">
              Loading...
            </div>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  );
}
