import { clsx } from "clsx";

export type RadioContainerProps = {
  readonly isSelected: boolean;
  readonly children: React.ReactNode;
  readonly isChildrenSelectable?: boolean;
  readonly onSelected: () => void;
  readonly onBlur?: () => void;
  readonly onFocus?: () => void;
};

export function RadioContainer({
  isSelected,
  isChildrenSelectable,
  children,
  onSelected,
  onBlur,
  onFocus,
}: RadioContainerProps): JSX.Element {
  return (
    <div className="flex items-stretch rounded-sm">
      <button
        type="button"
        aria-label="radio button"
        className={clsx(
          "flex w-10 cursor-pointer justify-center rounded-l-sm pt-[11px]",
          isSelected ? "bg-primary-900" : "border border-primary-300",
        )}
        onClick={onSelected}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div
          className={clsx(
            "size-4 rounded-full border border-background",
            isSelected ? "border-[3px]" : "border-primary-300",
          )}
        />
      </button>

      {isChildrenSelectable ? (
        <button
          type="button"
          className={clsx(
            "flex w-full items-center gap-2 rounded-r-sm border border-l-[0.1px] px-4 py-2 text-sm",
            isSelected ? "border-primary" : "border-primary-300",
          )}
          onClick={onSelected}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {children}
        </button>
      ) : (
        <div
          className={clsx(
            "flex w-full items-center gap-2 rounded-r-sm border border-l-[0.1px] px-4 py-2 text-sm",
            isSelected ? "border-primary" : "border-primary-300",
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}
