export type ChordFunction = "T" | "S" | "D";

export const CHORD_FUNCTIONS = {
  T: {
    name: "Tonic",
    abbreviation: "T",
  },
  S: {
    name: "Sub Dominant",
    abbreviation: "S",
  },
  D: {
    name: "Dominant",
    abbreviation: "D",
  },
} as const satisfies Record<
  ChordFunction,
  { name: string; abbreviation: ChordFunction }
>;

export const CHORD_FUNCTIONS_ARRAY = ["T", "S", "D"] as const;
