import { clsx } from "clsx";
import { SelectorButton } from "../../SelectorButton";
import { OutlineTitle } from "../_util/OutlineTitle";
import { AlternativeChordExpressionsLane } from "../_util/AlternativeChordExpressionsLane";
import type { AlternativeChordExpressionTypeExclude } from "~/libs/chordProgressionUtil/ALTERNATIVE_CHORD_EXPRESSIONS";
import {
  CHORD_FUNCTIONS,
  type ChordFunction,
} from "~/libs/chordProgressionUtil/ChordFunction";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type ChordFunctionSelectorProps = {
  readonly className?: string;
  readonly selectedChordFunction?: ChordFunction;
  readonly selectedAlternativeChordExpression?: AlternativeChordExpressionTypeExclude;
  readonly onClickChordFunction: (chordFunction: ChordFunction) => void;
  readonly onClickAlternativeChordExpression: (
    alternativeChordExpression: AlternativeChordExpressionTypeExclude,
  ) => void;
  readonly onClickClearInputButton: () => void;
  readonly onClickDeleteButton: () => void;
};

export function ChordFunctionSelector({
  className,
  selectedChordFunction,
  selectedAlternativeChordExpression,
  onClickChordFunction,
  onClickAlternativeChordExpression,
  onClickClearInputButton,
  onClickDeleteButton,
}: ChordFunctionSelectorProps): JSX.Element {
  const dict = useDictionary();

  return (
    <div className={clsx("flex", className)}>
      <div className="flex w-3/4 flex-col gap-4 break-all">
        <OutlineTitle>
          <div className="flex flex-col items-center justify-center gap-1">
            <span>{dict.please_select_the_function_of_the_code}</span>
          </div>
        </OutlineTitle>

        <div className="flex flex-1 justify-between gap-2 p-2">
          {Object.values(CHORD_FUNCTIONS).map(({ name, abbreviation }) => (
            <div
              className=""
              style={{
                flex: "1",
              }}
              key={name}
            >
              <SelectorButton
                isSelected={selectedChordFunction === abbreviation}
                className="size-full text-xl font-semibold outline ring-1 ring-ring"
                onClick={() => {
                  onClickChordFunction(abbreviation);
                }}
                placeholder={
                  <div className="w-full">
                    <div className="font-bold">{abbreviation}</div>
                    <div className="whitespace-pre-wrap text-xs">{name}</div>
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>

      <div className="w-1/4 border-l-2">
        <AlternativeChordExpressionsLane
          selectedAlternativeChordExpression={
            selectedAlternativeChordExpression
          }
          onClickAlternativeChordExpression={onClickAlternativeChordExpression}
          onClickClearInputButton={onClickClearInputButton}
          onClickDeleteButton={onClickDeleteButton}
        />
      </div>
    </div>
  );
}
