import type { DynamicRedirectWhiteListKey } from "~/libs/auth/DYNAMIC_REDIRECT_WHITELIST";
import { createQueryParamsString } from "~/libs/url/createQueryParamsString";
import { parseQueryParams } from "~/libs/url/parseQueryParams";

type QueryParams = {
  redirectToAfterRegistered?: DynamicRedirectWhiteListKey;
};

export function getAuthSignUpPagePath({
  queryParams,
}: {
  queryParams: QueryParams;
}): string {
  return `/auth/sign_up${createQueryParamsString<QueryParams>(queryParams)}`;
}

export function parseAuthSignUpPageQueryParams(
  queryString: string,
): Partial<QueryParams> {
  return parseQueryParams<QueryParams>(queryString, {
    redirectToAfterRegistered: "string",
  });
}
