import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from "~/components/shadcnui/ui/dropdown-menu";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/shadcnui/ui/command";

export type DropDownContentItem =
  | {
      readonly type: "separator";
    }
  | {
      readonly type: "sub";
      readonly icon?: React.ReactNode;
      readonly label: string;
      readonly contents: DropDownContentsProps["contents"];
    }
  | {
      readonly type: "label";
      readonly icon?: React.ReactNode;
      readonly label: string;
    }
  | {
      readonly type: "clickableLabel";
      readonly icon?: React.ReactNode;
      readonly label: string;
      readonly onClick: () => void;
    }
  | {
      readonly type: "filterableCommands";
      readonly filterPlaceholder: string;
      readonly emptyMessage: string;
      readonly commands: {
        id: string;
        label: string;
        isSelected?: boolean;
      }[];
      readonly onSelect: (id: string) => void;
    };

export type DropDownContentsProps = {
  readonly contents: DropDownContentItem[];
};

export function DropDownContents({
  contents,
}: DropDownContentsProps): JSX.Element {
  const createDropDownMenuContent = ({
    content,
    key,
  }: {
    readonly content: DropDownContentItem;
    key: number | string;
  }) => {
    switch (content.type) {
      case "separator": {
        return <DropdownMenuSeparator key={key} />;
      }
      case "sub": {
        return (
          <DropdownMenuSub key={key}>
            <DropdownMenuSubTrigger className="flex min-h-9 items-center gap-1 text-sm font-semibold sm:text-xs">
              <span className="ml-2.5">{content.icon}</span>
              <span>{content.label}</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent className="border-2 border-primary p-1 shadow-xl">
                {content.contents.map((subContent, subContentKey) =>
                  createDropDownMenuContent({
                    content: subContent,
                    key: `${key}-${subContentKey}`,
                  }),
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        );
      }

      case "label": {
        return <DropdownMenuLabel key={key}>{content.label}</DropdownMenuLabel>;
      }
      case "clickableLabel": {
        return (
          <DropdownMenuItem
            key={key}
            onClick={() => {
              content.onClick();
            }}
            className="flex min-h-9 items-center gap-1 px-2 pb-1 text-sm font-semibold sm:text-xs"
          >
            <span className="ml-2.5">{content.icon}</span>
            <span>{content.label}</span>
          </DropdownMenuItem>
        );
      }
      case "filterableCommands": {
        return (
          <Command key={key}>
            <CommandInput
              placeholder={content.filterPlaceholder}
              className="h-9"
            />
            <CommandList>
              <CommandEmpty>{content.emptyMessage}</CommandEmpty>
              <CommandGroup>
                {content.commands.map((command) => (
                  <CommandItem
                    key={command.label}
                    value={command.label}
                    onSelect={() => {
                      content.onSelect(command.id);
                    }}
                  >
                    {command.isSelected ? "✓ " : ""}
                    {command.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        );
      }
      default: {
        return undefined;
      }
    }
  };

  return (
    <>
      {contents.map((content, index) =>
        createDropDownMenuContent({
          content,
          key: index,
        }),
      )}
    </>
  );
}
