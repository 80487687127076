/* eslint-disable react/jsx-max-depth */
import { Link } from "@remix-run/react";
import { Icons } from "../_icons";
import {
  DropDownContents,
  type DropDownContentsProps,
} from "../DropDownContent";
import {
  type ChordProgressionSearchBarProps,
  ChordProgressionSearchBar,
} from "./ChordProgressionSearchBar";
import { Button } from "~/components/shadcnui/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/components/shadcnui/ui/dropdown-menu";
import { SHORT_APP_NAME } from "~/libs/APP_NAME";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import { getAuthLoginPagePath } from "~/routes/auth.login/path";
import { getAuthSignUpPagePath } from "~/routes/auth.sign_up._index/path";

export type SiteHeaderProps = {
  readonly iconHref: string;
  readonly chordProgressionSearchBarProps: ChordProgressionSearchBarProps;
  readonly dropDownMenuContentProps: DropDownContentsProps;
  readonly showSmartPhoneSearchBar: boolean;
  readonly isLogined: boolean;
  readonly onClickSmartPhoneOpenSearchButton: () => void;
};

export function SiteHeader({
  iconHref,
  chordProgressionSearchBarProps,
  dropDownMenuContentProps,
  showSmartPhoneSearchBar,
  isLogined,
  onClickSmartPhoneOpenSearchButton,
}: SiteHeaderProps): JSX.Element {
  const dict = useDictionary();

  const searchBars = {
    sm: (
      <>
        <div className="md:hidden">
          <Button
            variant="secondary"
            className="gap-1"
            onClick={() => {
              onClickSmartPhoneOpenSearchButton();
            }}
          >
            <span>{dict.search}</span>
            <Icons.Crud.Search size={20} />
          </Button>
        </div>
        {!!showSmartPhoneSearchBar && (
          <div className="fixed left-0 top-2 z-10 w-full px-4 md:hidden">
            <div className="mt-1 w-full rounded-sm border border-ring bg-background">
              <ChordProgressionSearchBar {...chordProgressionSearchBarProps} />
            </div>
          </div>
        )}
      </>
    ),
    overSm: (
      <div className="hidden w-full max-w-[800px] md:block">
        <ChordProgressionSearchBar {...chordProgressionSearchBarProps} />
      </div>
    ),
  };

  return (
    <div className="mx-auto flex max-w-7xl items-center justify-between gap-2 px-4">
      <div className="flex-1">
        <Link to={iconHref}>
          <div className="text-lg">{SHORT_APP_NAME}</div>
        </Link>
      </div>

      {/* 検索バー（smサイズ） */}
      {searchBars.sm}
      {/* 検索バー（md以上） */}
      {searchBars.overSm}

      <div className="flex flex-1 justify-end">
        {isLogined ? (
          <div className="hidden sm:block">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="default"
                  className="flex items-center gap-1 py-5"
                >
                  <Icons.Crud.Plus size={15} />
                  <span>{dict.add_new.toUpperCase()}</span>
                  <Icons.Util.Arrows.DownChevron size={14} className="ml-2" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="ml-4 border-2 border-primary shadow-xl">
                <DropDownContents {...dropDownMenuContentProps} />
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ) : (
          <div className="flex gap-2">
            <Link to={getAuthLoginPagePath()}>
              <Button
                variant="outline"
                className="flex items-center gap-2 py-5"
              >
                <span>{dict.login}</span>
              </Button>
            </Link>
            <Link
              to={getAuthSignUpPagePath({
                queryParams: {},
              })}
            >
              <Button
                variant="default"
                className="flex items-center gap-2 py-5"
              >
                <span>{dict.sign_up}</span>
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
