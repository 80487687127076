import { clsx } from "clsx";

export type CenteredPageBodyWideProps = {
  readonly children: React.ReactNode;
  readonly className?: string;
};

export function CenteredPageBodyWide({
  children,
  className,
}: CenteredPageBodyWideProps): JSX.Element {
  return (
    <div
      className={clsx(
        "mx-auto mt-8 px-4",
        "flex max-w-fit gap-10",
        // スマホサイズなら下上に並べ、そうでないなら横に並べる
        "flex-col md:flex-row",
        // 詰まった見た目にならないように、画面幅が大きい時は少し広げる
        "lg:min-w-[1100px] xl:min-w-[1300px]",
        className,
      )}
    >
      {children}
    </div>
  );
}
