export function SearchConditionPartLayout({
  title,
  condition,
}: {
  readonly title: string;
  readonly condition: React.ReactNode;
}): JSX.Element {
  return (
    <div>
      <div className="font-semibold">{title}</div>
      <div className="ml-4 mt-3">{condition}</div>
    </div>
  );
}
