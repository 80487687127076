import { clsx } from "clsx";
import type { ReactNode } from "react";

export function ChordElementLane({
  title,
  body,
  className,
  noScroll,
}: {
  readonly title?: ReactNode;
  readonly body: ReactNode;
  readonly noScroll?: boolean;
  readonly className?: string;
}): JSX.Element {
  return (
    <div className={clsx("w-fit", className)}>
      {!!title && (
        <div className="flex h-16 items-center justify-center break-words text-center text-xs font-bold sm:h-12">
          {title}
        </div>
      )}
      <div
        className={clsx(
          "flex flex-col gap-2 pt-1 text-center",
          noScroll ? "" : "h-60 overflow-y-scroll",
        )}
      >
        {body}
      </div>
    </div>
  );
}
