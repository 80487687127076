import { clsx } from "clsx";
import { SelectorButton } from "../../../SelectorButton";
import { ChordElementLane } from "../../_util/ChordElementLane";
import { OutlineTitle } from "../../_util/OutlineTitle";
import {
  ALTERNATIVE_CHORD_EXPRESSIONS,
  type AlternativeChordExpressionTypeExclude,
} from "~/libs/chordProgressionUtil/ALTERNATIVE_CHORD_EXPRESSIONS";
import { Button } from "~/components/shadcnui/ui/button";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type AlternativeChordExpressionsLaneProps = {
  readonly selectedAlternativeChordExpression?: AlternativeChordExpressionTypeExclude;
  readonly onClickAlternativeChordExpression: (
    alternativeChordExpression: AlternativeChordExpressionTypeExclude,
  ) => void;
  readonly onClickClearInputButton: () => void;
  readonly onClickDeleteButton: () => void;
};

export function AlternativeChordExpressionsLane({
  selectedAlternativeChordExpression,
  onClickAlternativeChordExpression,
  onClickClearInputButton,
  onClickDeleteButton,
}: AlternativeChordExpressionsLaneProps): JSX.Element {
  const dict = useDictionary();

  return (
    <>
      <OutlineTitle>{dict.or}</OutlineTitle>
      <ChordElementLane
        noScroll
        className="w-full"
        body={
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              {Object.entries(ALTERNATIVE_CHORD_EXPRESSIONS).map(
                ([key, { value, descriptionTranslationKey }]) => {
                  const isSelected = selectedAlternativeChordExpression === key;

                  return (
                    <div key={key} className="w-full px-1">
                      <SelectorButton
                        key={key}
                        isSelected={isSelected}
                        placeholder={
                          <div className="w-full">
                            <div className="font-bold">{value}</div>
                            <div
                              className={clsx(
                                "whitespace-pre-wrap text-[0.6rem] leading-3",
                              )}
                            >
                              {dict[descriptionTranslationKey]}
                            </div>
                          </div>
                        }
                        className="h-fit w-full"
                        onClick={() => {
                          onClickAlternativeChordExpression(
                            key as AlternativeChordExpressionTypeExclude,
                          );
                        }}
                      />
                    </div>
                  );
                },
              )}
            </div>
            <div className="flex flex-col gap-3 px-1">
              <Button
                variant="ghost"
                onClick={() => {
                  onClickClearInputButton();
                }}
                className="w-full whitespace-pre-wrap px-0 text-xs sm:text-sm"
              >
                {dict.clear}
              </Button>
              <Button
                variant="destructive"
                onClick={() => {
                  onClickDeleteButton();
                }}
                className="w-full"
              >
                {dict.delete}
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}
