import { createQueryParamsString } from "~/libs/url/createQueryParamsString";
import { parseQueryParams } from "~/libs/url/parseQueryParams";

type HomePagePathQueryParams = {};

export function getHomePagePath({}: HomePagePathQueryParams): string {
  return `/${createQueryParamsString<HomePagePathQueryParams>({})}`;
}

export function parseHomePagePathQueryParams(
  queryString: string,
): Partial<HomePagePathQueryParams> {
  return parseQueryParams<HomePagePathQueryParams>(queryString, {
    filter: "string",
  });
}
