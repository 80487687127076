import { clsx } from "clsx";
import { PlusIcon } from "@radix-ui/react-icons";
import { useKeyPressEvent } from "react-use";
import {
  SearchConditionInput,
  type SearchConditionInputProps,
} from "../../SearchConditionInput";
import { Icons } from "../../_icons";
import {
  ChordSelectorButtons,
  type ChordSelectorButtonsProps,
} from "../../ChordSelectorButtons";
import {
  SearchTypeSelector,
  type SearchTypeSelectorProps,
} from "./SearchTypeSelector";
import { Button } from "~/components/shadcnui/ui/button";
import { DataId } from "~/libs/DataId";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type ChordProgressionSearchBarProps = {
  readonly chordSelectorButtonsProps: ChordSelectorButtonsProps;
  readonly searchTypeSelectorProps: SearchTypeSelectorProps;
  readonly searchConditionInputProps?: SearchConditionInputProps;
  readonly onFireSubmitAction: () => void;
  readonly onActivateSearchBar: () => void;
  readonly onClickAddIcon: () => void;
  readonly onEscapeKeyDown: (e: KeyboardEvent) => void;
  readonly onChangeFreeInput: (value: string) => void;
};

// TODO: +を押したら、タブフォーカスは選択してるやつに移動
// TODO: 選択してるときにキー入力したら直接入力もできるようにする
export function ChordProgressionSearchBar({
  chordSelectorButtonsProps,
  searchTypeSelectorProps,
  searchConditionInputProps,
  onFireSubmitAction,
  onActivateSearchBar,
  onClickAddIcon,
  onEscapeKeyDown,
  onChangeFreeInput,
}: ChordProgressionSearchBarProps): JSX.Element {
  const dict = useDictionary();
  useKeyPressEvent("Escape", (e) => {
    onEscapeKeyDown(e);
  });

  const { searchType } = searchTypeSelectorProps;
  const isFreeInput = searchType === "free_input";

  return (
    <div className={clsx("relative w-full")}>
      <div
        data-id={DataId.ChordProgressionSearchBar_SearchBar}
        className={clsx(
          "grid w-full grid-flow-col rounded-sm",
          isFreeInput
            ? "[&:has(input:focus)]:ring-1 [&:has(input:focus)]:ring-ring [&:has(input:focus-visible)]:outline-none [&:has(input:focus-visible)]:ring-1 [&:has(input:focus-visible)]:ring-ring"
            : "focus:ring-1 focus:ring-ring focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
        )}
        style={{
          gridTemplateColumns: "1fr max-content",
        }}
      >
        {/* TODO: タブで移動した時になんかフォーカスするのが多いので省略したいな */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-element-interactions */}
        <form
          role="search"
          onSubmit={(e) => {
            e.preventDefault();
            onFireSubmitAction();
          }}
          className={clsx(
            "flex w-full cursor-text items-center rounded-l-sm border",
          )}
          style={{
            gridTemplateColumns: "max-content auto",
          }}
          onClick={(e) => {
            if (
              (e.target as HTMLElement).closest(
                `[data-id="${DataId.SearchTypeSelector_Content}"]`,
              )
            ) {
              return;
            }

            (e.target as HTMLElement).focus();
            onActivateSearchBar();
          }}
          aria-label="search"
          onKeyDown={(e) => {
            if (
              (e.target as HTMLElement).closest(
                `[data-id="${DataId.SearchTypeSelector_Content}"]`,
              )
            ) {
              return;
            }

            if (e.key === "Enter") {
              onActivateSearchBar();
            }
          }}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className="flex h-full p-1">
            <SearchTypeSelector {...searchTypeSelectorProps} />
          </div>

          {/* NOTE: 以下、表示非表示をhiddenで切り替えている（めんどくさくて状態管理してないから、そうしないと入力内容が消えちゃうため） */}
          <input
            className={clsx(
              "size-full bg-background outline-none",
              !isFreeInput && "hidden",
            )}
            onChange={(e) => {
              onChangeFreeInput(e.target.value);
            }}
          />
          <div
            className={clsx(
              "ml-2 flex items-center justify-center gap-x-2",
              isFreeInput && "hidden",
            )}
          >
            <ChordSelectorButtons {...chordSelectorButtonsProps} />

            {chordSelectorButtonsProps.selectorButtonPropsLists.length > 0 ? (
              <Button
                type="button"
                variant="outline"
                size="icon"
                className="size-8 min-h-8 min-w-8 rounded-full"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickAddIcon();
                }}
              >
                <PlusIcon height={20} width={20} />
              </Button>
            ) : (
              <div className="select-none text-primary-500">
                {dict.input_x(dict[searchType])}
              </div>
            )}
          </div>
        </form>
        <Button
          variant="outline"
          onClick={onFireSubmitAction}
          className="h-full min-w-14 rounded-l-none rounded-r-sm"
        >
          <Icons.Crud.Search width={20} />
        </Button>
      </div>

      {!!searchConditionInputProps && (
        <div className="absolute z-10 mt-1 h-[90dvh] w-full overflow-y-scroll rounded-sm border-2 border-ring bg-background">
          <SearchConditionInput {...searchConditionInputProps} />
        </div>
      )}
    </div>
  );
}
