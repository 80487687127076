"use client";

import { Link } from "@remix-run/react";
import { ChevronsUpDown } from "lucide-react";
import { clsx } from "clsx";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { Fragment } from "react";
import {
  SearchTypeOption,
  type SearchTypeOptionProps,
} from "./SearchTypeOption";
import type { ChordSearchType } from "./util";
import { Popover, PopoverTrigger } from "~/components/shadcnui/ui/popover";
import { Button } from "~/components/shadcnui/ui/button";
import { Separator } from "~/components/shadcnui/ui/separator";
import { DataId } from "~/libs/DataId";
import { Icons } from "~/components/common/_icons";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type OtherPageLink = { href: string; text: string };

export type SearchTypeSelectorProps = {
  readonly searchType: ChordSearchType;
  readonly searchTypeOptionPropsList: SearchTypeOptionProps[];
  readonly otherPageLinks: OtherPageLink[];
  readonly onClickSearchTypeSelector: () => void;
};

export function SearchTypeSelector({
  searchType,
  searchTypeOptionPropsList,
  otherPageLinks,
  onClickSearchTypeSelector,
}: SearchTypeSelectorProps): JSX.Element {
  const dict = useDictionary();

  // TODO: hoverで開くのも検討 https://github.com/radix-ui/primitives/issues/2051#issuecomment-1503141977
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            onClickSearchTypeSelector();
          }}
          className="h-full rounded-sm py-1 pl-4 pr-3 font-bold"
        >
          <div className="flex flex-col">
            <div className="align-middle text-3xs leading-[0.5rem]">
              {dict.search_by}
            </div>
            <div>{dict[searchType]}</div>
          </div>
          <ChevronsUpDown className="ml-2 size-4 shrink-0 px-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          data-id={DataId.SearchTypeSelector_Content}
          className="flex w-full max-w-[100vw] flex-col items-center px-2 sm:max-w-[460px] sm:p-0"
          align="start"
          sideOffset={10}
        >
          <div className="whitespace-pre-wrap rounded-md border bg-background shadow-md">
            {searchTypeOptionPropsList.map(
              ({ className, ...otherProps }, index) => (
                <Fragment key={index}>
                  <SearchTypeOption
                    {...otherProps}
                    className={clsx(
                      index === 0 && "rounded-t-sm",
                      index === searchTypeOptionPropsList.length - 1 &&
                        "rounded-b-sm",
                      className,
                    )}
                  />
                  {index !== searchTypeOptionPropsList.length - 1 && (
                    <Separator />
                  )}
                </Fragment>
              ),
            )}
          </div>

          {otherPageLinks.length > 0 && (
            <>
              <div className="w-full bg-background/80 py-2 text-center text-xs font-semibold">
                {dict.search_history}
              </div>
              <div className="flex w-full flex-col rounded-md border">
                {otherPageLinks.map(({ href, text }, index) => (
                  <Fragment key={index}>
                    <Link
                      key={href}
                      to={href}
                      className={clsx(
                        "flex w-full flex-nowrap items-center bg-background px-6 py-4 shadow-md hover:bg-accent",
                        index === 0 && "rounded-t-sm",
                        index === otherPageLinks.length - 1 && "rounded-b-sm",
                      )}
                    >
                      <div className="w-full text-sm">{text}</div>
                      <Icons.Util.Arrows.Right width={15} className="ml-2" />
                    </Link>
                    {index !== searchTypeOptionPropsList.length && (
                      <Separator className="w-full" />
                    )}
                  </Fragment>
                ))}
              </div>
            </>
          )}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </Popover>
  );
}
