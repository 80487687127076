import { Link } from "@remix-run/react";
import { clsx } from "clsx";

export type SiteMenuLinkProps = {
  readonly href: string;
  readonly icon: React.ReactNode;
  readonly label: string;
  readonly isSelected: boolean;
  readonly count?: number;
  readonly className?: string;
};

export function SiteMenuLink({
  href,
  icon,
  label,
  isSelected,
  count,
  className,
}: SiteMenuLinkProps): JSX.Element {
  return (
    <Link
      to={href}
      className={clsx(
        "flex flex-nowrap items-center px-2 pb-1 text-sm font-semibold hover:text-foreground/80 sm:text-xs",
        isSelected ? "border-b-2 border-primary" : "",
        className,
      )}
    >
      <span className="text-foreground/60">{icon}</span>
      <span className="ml-1 w-full">{label.toUpperCase()}</span>
      {count ? <span className="mx-1">({count})</span> : undefined}
    </Link>
  );
}
