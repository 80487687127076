import { clsx } from "clsx";

export type SearchTypeOptionProps = {
  readonly label: string;
  readonly description: string;
  readonly isSelected: boolean;
  readonly className?: string;
  readonly onClick: () => void;
};

export function SearchTypeOption({
  label,
  description,
  isSelected,
  className,
  onClick,
}: SearchTypeOptionProps): JSX.Element {
  return (
    <button
      className={clsx(
        "flex w-full cursor-pointer flex-col gap-1 px-6 py-4",
        isSelected ? "bg-primary text-primary-foreground" : "hover:bg-accent",

        className,
      )}
      type="button"
      onClick={onClick}
    >
      <div className="text-sm font-bold">{label}</div>
      <div className="text-xs">{description}</div>
    </button>
  );
}
